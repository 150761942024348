"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EMercuryElectronicAccountType = exports.EMercuryPayoutStatus = void 0;
var EMercuryPayoutStatus;
(function (EMercuryPayoutStatus) {
    EMercuryPayoutStatus["REJECTED"] = "rejected";
    EMercuryPayoutStatus["PENDING"] = "pending";
    EMercuryPayoutStatus["COMPLETED"] = "completed";
})(EMercuryPayoutStatus || (exports.EMercuryPayoutStatus = EMercuryPayoutStatus = {}));
var EMercuryElectronicAccountType;
(function (EMercuryElectronicAccountType) {
    EMercuryElectronicAccountType["BUSINESS_CHECKING"] = "businessChecking";
    EMercuryElectronicAccountType["BUSINESS_SAVINGS"] = "businessSavings";
    EMercuryElectronicAccountType["PERSONAL_CHECKING"] = "personalChecking";
    EMercuryElectronicAccountType["PERSONAL_SAVINGS"] = "personalSavings";
})(EMercuryElectronicAccountType || (exports.EMercuryElectronicAccountType = EMercuryElectronicAccountType = {}));
